import { Loader } from '@googlemaps/js-api-loader'
import { browser } from '$app/environment'
import { sendGTM } from '$lib/analytics'
import { t } from '$lib/translations'
import type { FveComponent } from '$lib/types/woltadvisor'
import {
	PUBLIC_GOOGLE_MAP_API_KEY,
	PUBLIC_WOLTAIR_WEB_CODE,
	PUBLIC_ENVIRONMENT
} from '$env/static/public'
import { tick } from 'svelte'
import { getFlagValue } from '@woltair/feature-flags-fe/client'
import { ORGANIZATION } from '$lib/consts'
import type { MarketplaceLocationCityResponse } from '$lib/types/location'

export async function addQueryParam(
	newParam: string,
	newValue: string,
	pageHref: string
): Promise<string> {
	await tick()
	if (!newParam || !newValue) {
		logger.error('Invalid parameters for addQueryParam', { newParam, newValue })
		return ''
	}

	try {
		const currentUrl = new URL(pageHref)
		currentUrl.searchParams.set(encodeURIComponent(newParam), encodeURIComponent(newValue))
		const newUrl = currentUrl.toString()
		return newUrl
	} catch (error) {
		logger.error('Error in addQueryParam:', error)
		return ''
	}
}

export function removeNBSP(string: string): string {
	return string?.replace(/&nbsp;/g, ' ') || ''
}

export function reducer(array: number[] = []): number {
	const result = Array.isArray(array)
		? array.reduce((accumulator, curr) => accumulator + curr, 0)
		: array
	return result
}

export function shiftToBeginning<T>(indices: number[], arr: T[]): T[] {
	const brands = [...arr]
	const objectsToShift: T[] = []

	for (let i = 0; i < indices.length; i++) {
		const index = indices[i]
		const object = brands.splice(index, 1)[0]
		objectsToShift.push(object)
	}

	for (let i = 0; i < objectsToShift.length; i++) {
		brands.unshift(objectsToShift[i])
	}
	return brands
}
export function pushIfExists<T>(array: T[], value: T): void {
	if (value !== -1) {
		array.push(value)
	}
}

interface Item {
	title?: string
	name?: string
}

export function sortOrder<T extends Item>(arr: T[], order: string[]): T[] {
	return arr.sort((a, b) => {
		const aKey = a.title ?? a.name ?? ''
		const bKey = b.title ?? b.name ?? ''

		if (order.indexOf(aKey) === -1) return 1
		if (order.indexOf(bKey) === -1) return -1
		if (aKey === bKey) return 0
		return order.indexOf(aKey) - order.indexOf(bKey)
	})
}

interface Tag {
	name: string
}

interface Resource {
	tags: Tag[]
}

export function getResourceByTag(resources: Resource[], tagName: string): Resource | undefined {
	return resources?.find((resource) => resource.tags.find((tag) => tag.name === tagName))
}

interface Tag {
	name: string
}

interface Resource {
	tags: Tag[]
	contentType: string
	url?: string
}

export function getEmployeeImage(resources: Resource[]): string | undefined {
	if (resources?.length) {
		const images = resources.filter((res) => {
			const tags = res.tags.map((tag) => tag?.name)
			return tags.includes('employee') && res.contentType.includes('image') && res.url
		})
		if (images.length && images[0]?.url) return images[0].url
	}
	return
}

export function tArray(key: string) {
	const texts: string[] = []

	let i = 1
	while (t(`${key}.${i}`) !== `${key}.${i}`) {
		texts.push(t(`${key}.${i}`) as string)
		i++
	}

	return texts
}

let cachedGeocored: google.maps.Geocoder | null = null

async function geocoder(): Promise<google.maps.Geocoder> {
	if (typeof window !== 'undefined' && !window?.google) await mapLoader()
	if (!cachedGeocored) cachedGeocored = new window.google.maps.Geocoder()
	return cachedGeocored
}

export function mapLoader() {
	const loader = new Loader({
		apiKey: PUBLIC_GOOGLE_MAP_API_KEY,
		version: 'beta',
		libraries: ['places']
	})

	return loader.load()
}

export async function createBounds() {
	if (browser && !window?.google) await mapLoader()
	return new window.google.maps.LatLngBounds()
}

type GeocodeRequest = {
	address?: string
	placeId?: string
	location?: google.maps.LatLng | google.maps.LatLngLiteral
}

type GeocodeResult = google.maps.GeocoderResult[]

export async function getLocationFromAddress({
	address,
	placeId,
	location
}: GeocodeRequest): Promise<GeocodeResult | null> {
	const coder = await geocoder()

	const request: google.maps.GeocoderRequest = {}
	if (address) request.address = address
	if (placeId) request.placeId = placeId
	if (location) request.location = location

	return new Promise((resolve, reject) => {
		coder
			.geocode(request, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					resolve(results)
				} else {
					reject(`Geocode was not successful for the following reason: ${status}`)
				}
			})
			.catch((error) => {
				logger.error('utils.getLocationFromAddress.error', 'Geocode error', error)
				return null
			})
	})
}

export function getMyLocation(callback: (position: GeolocationPosition) => void): void {
	if (navigator?.geolocation) {
		navigator.geolocation.getCurrentPosition(callback)
	} else {
		// Browser doesn't support Geolocation
		logger.error('utils.getMyLocation.error', `Browser doesn't support Geolocation`, navigator)
	}
}

// export function buildAddress(address, short) {
// 	if (!address) return false
//
// 	if (
// 		address?.addressType === ADDRESS_TYPE.FULL_ADDRESS &&
// 		(address.street === 'unknown' || address.zip === 'unknown')
// 	) {
// 		return `NEÚPLNÁ ADRESA - ${address.city}`
// 	} else if (address?.addressType === ADDRESS_TYPE.FULL_ADDRESS) {
// 		if (short) return `${address.street}, ${address.city}`
// 		return `${address.street}, ${address.city}, ${address.zip}`
// 	}
// 	if (address?.addressType === ADDRESS_TYPE.COORDINATES) {
// 		if (short || !address?.city) return `${address.gpsLat}, ${address.gpsLng}`
// 		return `${address.gpsLat}, ${address.gpsLng} (${address.city})`
// 	}
// }

// export function buildGAddress(address) {
// 	if (!address) return false
// 	function replaceSpace(string) {
// 		return string.replace(' ', '+')
// 	}
// 	if (address?.addressType === ADDRESS_TYPE.FULL_ADDRESS) {
// 		return `${replaceSpace(address.street)}%2C+${replaceSpace(address.zip)}%2C+${replaceSpace(
// 			address.city
// 		)}`
// 	}
// 	if (address?.addressType === ADDRESS_TYPE.COORDINATES) {
// 		return `${address.gpsLat}%2C+${address.gpsLng}`
// 	}
// }

export function getAddressComponent(
	addressResponse: google.maps.GeocoderResult,
	keys: string
): string {
	const location = addressResponse.address_components.find((component) =>
		component.types.some((type) => keys.includes(type))
	)
	return location?.short_name
}

export async function autocompleteAddress(str, options) {
	const searchString = str.trim()
	if (searchString === '') return []
	if (!window?.google?.maps?.places) await mapLoader()
	const placeAutocompleteService = new google.maps.places.AutocompleteService()
	const { predictions: withoutStreetNumber } = await placeAutocompleteService.getPlacePredictions({
		input: searchString,
		types: ['address'],
		language: options?.lang,
		componentRestrictions: options?.restrictions
	})

	return withoutStreetNumber
}

// countVisiblePages - Used for pagination on blog and expert_advice.
export function countVisiblePages(currentPage: number, totalPages: number, offsetPagination = 3) {
	let start = Math.max(1, currentPage - offsetPagination)
	let end = Math.min(totalPages, currentPage + offsetPagination)
	let pages: (number | string)[] = Array.from({ length: end - start + 1 }, (_, i) => start + i)

	if (start >= offsetPagination) pages.unshift('...')
	if (start + 1 >= offsetPagination) pages.unshift(1)
	if (totalPages - end > 1) pages.push('...')
	if (totalPages !== end) pages.push(totalPages)

	return pages
}

//Cookie management

export function setCookie(name: string, value: string, days: number) {
	let expires = ''
	if (days) {
		const date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		if (days) {
			expires = '; expires=' + date.toUTCString()
		}
	}
	document.cookie = name + '=' + (value || '') + expires + '; SameSite=Lax; path=/;'
}

export function setCookieCustom(
	name: string,
	value: string,
	options?: {
		secure?: boolean
		sameSite?: 'lax' | 'strict' | 'none'
		path?: string
		domain?: string
		maxAge?: number
	}
): () => void {
	let expires = ''
	if (options?.maxAge) {
		const date = new Date()
		date.setTime(date.getTime() + options.maxAge * 1000)
		expires = '; expires=' + date.toUTCString()
	}

	const cookieOptions = !options
		? ''
		: Object.entries(options)
				.map(([key, value]) => {
					if (key === 'maxAge') return ''
					if (key === 'domain' || value.toString().includes('localhost')) return `${key}=localhost`
					return `${key}=${value}`
				})
				.filter((option) => option !== '')
				.join('; ')

	document.cookie = name + '=' + (value || '') + expires + `; ${cookieOptions};`
	return () => {}
}

export function getCookie(name: string) {
	const nameEQ = name + '='
	const ca = document.cookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') c = c.substring(1, c.length)
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
	}
	return null
}

export function deleteCookie(name: string) {
	document.cookie = name + '=; Max-Age=-99999999; path=/'
}
export function getSpecificParam(param: string, pageHref: string): string | undefined {
	const url = new URL(pageHref)
	return url.searchParams.get(param) ?? undefined //?? 'c6d5dda3-a9da-4238-8ae4-9ffb5c57e6ae'
}

export function returnPathName(pathname: string) {
	// last character is a slash
	if (pathname.slice(-1) === '/') {
		return pathname.slice(0, -1)
	}
	return pathname
}
export const getKeyByValue = (object, value) => {
	if (!object) return
	return Object.keys(object).find((key) => object[key] === value)
}
export const findValueInObject = (obj: any, valueToFind: any) => {
	if (!obj) {
		console.error('No obj provided')
		return
	}
	// Check if the current obj is the value we're looking for
	if (obj === valueToFind) {
		return obj
	}

	// If the current obj is an array or object, search its values
	if (typeof obj === 'object' && obj !== null) {
		for (const value of Object.values(obj)) {
			const result = findValueInObject(value, valueToFind)
			if (result !== null) {
				return result
			}
		}
	}

	return null
}

export function escapeHtml(text: string) {
	return text
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/\//g, '&#47;')
		.replace(/'/g, '&#039;')
}

export function string_to_slug(str: string) {
	if (typeof str !== 'string' || !str) return ''

	// Character mapping for Czech, Polish, German, Italian, and other common characters
	const from = 'àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþßŕąćęłńóśźżčďěňřšťůž'
	const to = 'aaaaaaaceeeeiiiionoooooouuuuytbracelnoszzcdenrstuz'

	return str
		.trim() // Trim whitespace
		.toLowerCase() // Convert to lowercase
		.split('')
		.map((char) => {
			const index = from.indexOf(char)
			return index !== -1 ? to.charAt(index) : char
		})
		.join('')
		.normalize('NFD') // Normalize string to decompose any remaining accented characters
		.replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
		.replace(/[^a-z0-9 -]/g, '') // Remove non-alphanumeric characters except spaces and hyphens
		.replace(/\s+/g, '-') // Replace spaces with hyphens
		.replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
}

export async function downloadURI(url: string, filename: string) {
	const response = await fetch(url)
	if (!response.ok) {
		throw new Error(`Failed to fetch ${url}: ${response.statusText}`)
	}
	const blob = await response.blob()
	const link = document.createElement('a')
	link.href = URL.createObjectURL(blob)
	link.download = filename
	document.body.appendChild(link)
	link.click()
	link.remove()
	URL.revokeObjectURL(link.href)
}

export function cropWordLength(text: string, n = 85) {
	if (!text) return ''
	text = text.replace(/<[^>]*>/g, '')
	if (text.length <= n) {
		return text
	}
	let lastIndex = text.substring(0, n).lastIndexOf(' ')
	if (lastIndex === -1) {
		lastIndex = n
	}
	return `${text.substring(0, lastIndex)}...`
}

export async function validatePhone(
	value: string | undefined
): Promise<{ isValid: boolean; number: string }> {
	const response = await fetch('/api/validation/phone', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			phone: value
		})
	})

	if (response.ok) {
		const data: { valid: boolean; number: string } = await response.json()
		if (data.valid) {
			return {
				isValid: true,
				number: data.number
			}
		}
	}
	return {
		isValid: false,
		number: value
	} as { isValid: boolean; number: string }
}

export async function validatePromoCode(
	value: string | undefined
): Promise<{ isValid: boolean; code: string }> {
	const response = await fetch('/api/validation/promo-code', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			code: value
		})
	})

	if (response.ok) {
		const data: { valid: boolean; code: string } = await response.json()
		if (data.valid) {
			return {
				isValid: true,
				code: data.code
			}
		}
	}
	return {
		isValid: false,
		code: value
	} as { isValid: boolean; code: string }
}

export async function validateZipCode(
	value: string
): Promise<{ isValid: boolean; zipcode: string }> {
	const response = await fetch('/api/location/city-zip', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ zip: value })
	})

	if (response.ok) {
		const data: Record<string, string>[] = await response.json()
		if (data?.[0]?.zip === value.replace(/ /g, '')) {
			return {
				isValid: true,
				zipcode: value
			}
		}
	}
	return {
		isValid: false,
		zipcode: value
	} as { isValid: boolean; zipcode: string }
}

export function resizeCDNImage(url: string, width = 1200): string {
	if (!url) return url
	const imageUrl = new URL(url)
	const pathnameArray = imageUrl.pathname?.split('/')
	let imgUrlResized: string = url

	//check for Cloudflare / Imagedelivery images
	if (
		(imageUrl.origin === 'https://image-web.wacdn.net' ||
			['https://imagedelivery.net', 'image-web.wacdn.net'].includes(imageUrl.origin)) &&
		pathnameArray?.[pathnameArray?.length - 1] === 'public'
	) {
		imgUrlResized =
			imageUrl.origin && imageUrl.pathname
				? `${imageUrl.origin}${imageUrl.pathname
						.split('/')
						.map((part, i, arr) => {
							if (part === 'public' && i === arr.length - 1) return `width=${width}`
							return part
						})
						.join('/')}`
				: url
	}
	//check for wacdn / Google images
	else if (
		imageUrl.host === 'lh3.googleusercontent.com' ||
		imageUrl.host === 'image-web.dev.wacdn.dev' ||
		imageUrl.host === 'image-web.wacdn.net'
	) {
		return `${imageUrl.href}?w=${width}`
	}
	//return original URL if no match
	return imgUrlResized
}
export function getAttributeDetails(componentData: FveComponent, title: string) {
	if (!componentData?.attributes) return null
	const attribute = componentData.attributes.find((attribute) => attribute?.title === title)
	if (!attribute || attribute?.unit == '') return null
	if (attribute?.unit) {
		return { unit: attribute.unit, value: attribute.value }
	} else {
		logger.warn('utils.getAttributeDetails', '⚠️ FVE Catalog ⚠️ Item is missing info', attribute)
		return null // should not happen, catalog might be set up incorrectly or gql has changed
	}
}
export function getComponentAttribute(
	type: string,
	component: FveComponent,
	attribute: false | string = false
) {
	if (!attribute) return
	switch (type) {
		case 'panel':
		case 'PANEL':
			return getAttributeDetails(component, attribute || 'Performance')
		case 'inverter':
		case 'INVERTER':
			return getAttributeDetails(component, attribute || 'Performance')
		case 'battery':
		case 'BATTERY':
			return getAttributeDetails(component, attribute || 'PVBatteryCapacity')
		default:
			break
	}
}
export function queryStringToObject(queryString: string): { [key: string]: string } {
	// Remove the leading '?' if it exists
	if (queryString.startsWith('?')) {
		queryString = queryString.substring(1)
	}

	const pairs = queryString.split('&')
	const result: { [key: string]: string } = {}

	for (const pair of pairs) {
		const [key, value] = pair.split('=')
		// Decode URI components to handle special characters
		result[decodeURIComponent(key)] = decodeURIComponent(value)
	}

	return result
}

export function slugify(str: string) {
	if (!str) return ''
	return String(str)
		.normalize('NFKD')
		.replace(/[\u0300-\u036f]/g, '')
		.trim()
		.toLowerCase()
		.replace(/[^a-z0-9 -]/g, '')
		.replace(/\s+/g, '-')
		.replace(/-+/g, '-')
}

export function mimeTypeToExtension(mimeType: string): string {
	const mimeTypes: { [key: string]: string } = {
		'application/pdf': 'pdf',
		'image/jpeg': 'jpeg',
		'image/png': 'png',
		'image/gif': 'gif',
		'text/plain': 'txt',
		'application/msword': 'doc',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
		'application/vnd.ms-excel': 'xls',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
		'application/zip': 'zip',
		'application/json': 'json',
		'application/xml': 'xml',
		'text/html': 'html',
		'application/javascript': 'js',
		'text/css': 'css',
		'application/vnd.ms-powerpoint': 'ppt',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
		'audio/mpeg': 'mp3',
		'audio/wav': 'wav',
		'video/mp4': 'mp4',
		'video/x-msvideo': 'avi'
	}

	return mimeTypes[mimeType] || ''
}
function getAdvisorSettings() {
	let wastyle: string | null = getCookie('wastyle')
	if (!wastyle) {
		let rand = Math.random()
		let wastyleValue
		if (rand < 0.33) {
			wastyleValue = 'alpha'
		} else if (rand > 0.66) {
			wastyleValue = 'gamma'
		} else {
			wastyleValue = 'beta'
		}
		setCookie('wastyle', wastyleValue, 30)
		wastyle = wastyleValue
	}
	return wastyle
}

export async function setWASettings(): Promise<{ blurPrice: boolean; needsLead: boolean }> {
	let blurPrice, needsLead
	const hvacLead = getCookie('PV_LEAD_UID')
	const pvLead = getCookie('HVAC_LEAD_UID')
	const wastyle: string | null = 'alpha' //getAdvisorSettings()
	if (hvacLead || pvLead || PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_CZ') {
		blurPrice = false
		needsLead = false
	} else if (wastyle == 'alpha' || PUBLIC_WOLTAIR_WEB_CODE == 'WOLTAIR_IT') {
		needsLead = true
		blurPrice = true

		try {
			await sendGTM(
				'set_ab_test',
				{},
				{
					ab_test: 'MARKETPLACE_BLUR_VIEW'
				}
			)
		} catch (error) {
			logger.error('utils.setWASettings.blurView.error', 'GTM Not sent', error)
		}
	} else if (wastyle == 'beta') {
		blurPrice = true
		needsLead = false
		try {
			await sendGTM(
				'set_ab_test',
				{},
				{
					ab_test: 'MARKETPLACE_BLUR_PRICE'
				}
			)
		} catch (error) {
			logger.error('utils.setWASettings.blurPrice.error', 'GTM Not sent', error)
		}
	} else {
		blurPrice = false
		needsLead = false
		try {
			await sendGTM(
				'set_ab_test',
				{},
				{
					ab_test: 'MARKETPLACE_BLUR_CONTROL'
				}
			)
		} catch (error) {
			logger.error('utils.setWASettings.blurControl.error', 'GTM Not sent', error)
		}
	}
	return { blurPrice, needsLead }
}
export type leadEvent = Record<string, any> & {
	detail: {
		leadUid: string
	}
}

export type FormType = 'hvac' | 'pv' | 'unsorted'
export const setLeadCookie = function (event: leadEvent, type: FormType): void {
	if (event.detail?.leadUid) {
		let cookieName = 'LEAD_UID'
		switch (type) {
			case 'pv':
				cookieName = 'PV_LEAD_UID'
				break
			case 'hvac':
				cookieName = 'HVAC_LEAD_UID'
				break
			default:
				break
		}
		setCookie(cookieName, event.detail?.leadUid, 30)
		return
	} else {
		logger.error('utils.setLeadCookie.error', 'Lead is missing LEAD UID', event.detail)
	}
}

export function formatBytes(bytes: number) {
	if (bytes === 0) return '0 Bytes'
	const k = 1024
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	const i = Math.floor(Math.log(bytes) / Math.log(k))
	const value = parseFloat((bytes / Math.pow(k, i)).toFixed(2))
	return `${value} ${sizes[i]}`
}

export async function getSelfServiceLink(
	leadUid: string,
	hostname: string,
	callback: (value: string) => void
): Promise<void> {
	const selfSxFlag = await getFlagValue('self-service-superfix', (val) => val)

	const domain = hostname
		? hostname.replace('www.', '').split('.').slice(-2).join('.')
		: ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].domain.toLowerCase()

	if (selfSxFlag === true) {
		callback(
			`https://account.${
				PUBLIC_ENVIRONMENT === 'staging' ? 'staging.' : ''
			}${domain}/selfservice/${leadUid}`
		)
	} else {
		callback(`https://selfservice.${domain}/${leadUid}`)
	}
}

// Marketplace vs. leadgen form implementation on BLOG as in SX-8230
export async function getBlogABTest(): Promise<boolean> {
	let serveAB
	let blogTest: string | null = getCookie('BlogLeadMP')
	if (!blogTest) {
		let rand = Math.random()
		if (rand < 0.5) {
			blogTest = 'T' //TEST
		} else {
			blogTest = 'C' //Control
		}
		setCookie('BlogLeadMP', blogTest, 30)
	}
	const hvacLead = getCookie('PV_LEAD_UID')
	const pvLead = getCookie('HVAC_LEAD_UID')
	if (hvacLead || pvLead) {
		serveAB = false
	} else if (blogTest == 'C') {
		serveAB = false
		try {
			await sendGTM(
				'set_ab_test',
				{},
				{
					ab_test: 'BLOG_LEAD_NONMANDATORY'
				}
			)
		} catch (error) {
			logger.error('utils.setWASettings.preLeadAB.error', 'GTM Not sent', error)
		}
	} else {
		serveAB = true
		try {
			await sendGTM(
				'set_ab_test',
				{},
				{
					ab_test: 'BLOG_LEAD_MANDATORY'
				}
			)
		} catch (error) {
			logger.error('utils.setWASettings.preLeadAB.error', 'GTM Not sent', error)
		}
	}

	return serveAB
}

export async function getCityNameFromZip(zipInput: string): Promise<string> {
	if (zipInput.length >= 5) {
		try {
			const res = await fetch('/api/location/city-zip', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ zip: zipInput })
			})

			if (!res.ok) {
				return ''
			}

			const data = (await res.json()) as MarketplaceLocationCityResponse[]

			const result = data
				.map((item: { zip: string; name: string }) => ({
					value: item.zip,
					label: item.name
				}))
				.filter((item) => item.value === zipInput)[0].label

			if (!result) {
				logger.error('utils.getCityNameReal.error', 'zip', zipInput, 'No result')
				return ''
			}

			return result
		} catch (err) {
			logger.error('utils.getCityNameReal.error', 'zip', zipInput, 'Fetch failed', err)
			return ''
		}
	} else {
		logger.error('utils.getCityNameReal.failed', 'zipInput is too short', zipInput)
		return ''
	}
}
